@use '@/styles/utils/mixins.scss' as *;

.customTabsWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.5rem;

  @include tab() {
    display: flex;
    flex-direction: column;
  }

  .tabBar {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    @include tab() {
      flex-direction: row;
      overflow-x: auto;
      width: 100%;
      padding-bottom: 0.25rem;
    }
  }

  .tabButton {
    display: flex;
    align-items: baseline;
    font-size: 1.5rem;
    gap: 0.25rem;
    color: $neutral07;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    width: max-content;
    position: relative;
    font-weight: 500;

    h3 {
      font-weight: normal;
      font-size: inherit;
    }

    @include tab() {
      font-size: 1rem;
      text-wrap: nowrap;
      font-weight: 400;
    }

    .productType {
      font-size: 0.875rem;

      @include tab() {
        font-size: 1rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0.063rem;
      width: 0;
      background-color: $primary;
      transition: width 0.3s;
    }

    &:hover::before,
    &:focus::before,
    &:active::before,
    &.active::before {
      width: 100%;
      transition: width 0.3s;
    }

    &:active,
    &:focus,
    &:hover,
    &.active {
      color: $primary;
    }

    &.active {
      @include tab() {
        font-weight: bold;
      }
    }
  }

  .tabContent {
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    color: $dark;

    @include tab() {
      padding-right: 0rem;
    }

    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 1.875rem;
      border-radius: 1.25rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary;
      font-weight: 500;
      margin-bottom: 0.75rem;
    }

    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 4.5rem;
    }

    h3 {
      font-size: 3rem;
    }

    h5 {
      font-size: 2.125rem;
    }

    h6 {
      font-size: 1.5rem;
    }

    p {
      margin-bottom: 1rem;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }
  }
}
